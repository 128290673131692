import {
  getQsc,
} from "./utils";

export const qsc = getQsc(); // this is needed here bc grabbing anything from index is causing a circular dependency error in Jest
const startTime = Date.now();
export const revgenOn = window.location.href.includes('revgen_debug=true') ? true : false;
export const verbose = window.location.href.includes('verbose') ? true : false;

export const setTime = (startTime) => {
  let time = 0;
  if ((Date.now() - startTime) < 1000) {
    time = `${Date.now() - startTime} ms`;
  } else {
    time = `${(Date.now() - startTime)/1000} seconds`
  }
  return time;
}

export const adLogger = (serviceName = 'RevgenAdService', message = {}, severity = 'INFO') => {
  if (revgenOn) {
    log(serviceName, message, severity, verbose); 
  }
};

export const log = (serviceName, message, severity, verbose) => {
  switch (severity) {
    case 'WARN':
      console.warn("%c%s", "background:yellow", `${setTime(startTime)} - [Warn] - ${serviceName} - ${message}`);
      break;
    case 'LOGGERV1':
        console.info("%c%s", "background:#D4F7FF", `${setTime(startTime)} - [Logger V1] - ${serviceName} - ${message}`);
        break;
    case 'ERROR':
      console.error(`${setTime(startTime)} - [Error] - ${serviceName} - ${message}`);
      break;
    case 'DEBUG':
      console.debug = console.log("%c%s", "background:#b4e5f6", `${setTime(startTime)} - [Debug] - ${serviceName} - ${message}`); // adaptation from node / still needed
      break;
    case 'SLOTDATA':
      cleanupSlots(serviceName, message, verbose);
      break;
    case 'DATA':
      adData(serviceName, message, verbose);
      break;
    case 'INFO':
    default:
      console.info("%c%s", "background:#d0ffe5", `${setTime(startTime)} - [Info] - ${serviceName} - ${message}`);
      break;
  }
};

adLogger("loggerV2", "Instantiating new logger", "INFO");

export const cleanupSlots = (serviceName, slots, verbose) => {
  slots.forEach((slot) => {
    if (verbose === true) {
      console.info("%c%s", "background: #faf0ff", `${setTime(startTime)} - [SlotData] - ${serviceName} - `);
      console.info("%c%s", "background-color: #faf0ff", JSON.stringify(slot.slotMap, null, 4))
    } else {
      console.info("%c%s", "background: #faf0ff", `${setTime(startTime)} - [SlotData] - ${serviceName} - slotName - ${slot.slotName}`);
      console.info("%c%s", "background: #faf0ff", `${setTime(startTime)} - [SlotData] - ${serviceName} - divId - ${slot.divId}`);
      console.info("%c%s", "background: #faf0ff", `${setTime(startTime)} - [SlotData] - ${serviceName} - size(s) - ${JSON.stringify(slot.slotMap.sizes)}`);
    }
  })
}

export const adData = (serviceName, data, verbose) => {
  if (verbose === true) {
    console.info("%c%s", "background: #d0ffe5", `${setTime(startTime)} - [Data] - ${serviceName} - `);
    console.info("%c%s", "background-color: #d0ffe5", JSON.stringify(data, null, 4))
  } else {
    console.info("%c%s", "background: #d0ffe5", `${setTime(startTime)} - [Data] - ${serviceName} - Data - ${JSON.stringify(Object.keys(data), null, 4)}`);
  }
}
