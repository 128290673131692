/**
 * @file index.js
 * @author Constantinos Davanos
 */
import Sleeper from '@advance-local/sleeper';
import { polyfill } from "es6-promise";
import assign from "lodash.assign";
import mergeWith from "lodash.mergewith";
import API from "./api.js";
import DataSources from "./sources";
import {
  checkAdsEnabled,
  checkHeaderBidding,
  getScript,
  setDebuggerLink,
  isInViewport,
} from "./utils";
import {
  addToConfig,
  config,
  adDebug,
  affiliateData,
  dfpSlotMap,
  getPlatform,
} from "./config/config";
import GPT from "./gpt/gpt";
import domready from "domready";
import {
  createRubiconScript,
  createAmazonScript,
  disableAllHb,
  createGPTScript,
  disableAllAds,
  checkRubiconFlags,
  checkAmznAdsEnabled,
  checkAmznAdsDisabled,
} from "../src/parallelRTB/rtb";
import {
  adLogger,
  qsc,
} from "./loggerV2";
import {
  createLotameLightningScript,
  runLotameLightningScripts,
} from "./targeting/lotameLightning";

function getPageType() {
  var rgPageType = "";
  return (rgPageType =
    (window.adiData || {}).pageType || window.m_page_type || false);
}

const ENVIRONMENT = typeof window !== 'undefined' && window.location.hostname.match(/-uat|sandbox.advancelocal.arcpublishing.com|localhost/) ? 'SANDBOX' : 'PRODUCTION';
const PLATFORM = getPlatform();
const PAGETYPE = getPageType();
export const adRefreshData = {};

let PRODUCT = ''; // instantiation needed to be moved up for Jest testing
// modification for undefined value check - REVGEN-829
let getProduct = () => {
  if (typeof window.rg_product !== 'undefined') {
    PRODUCT = window.rg_product;
  } else if (typeof window.adiData !== 'undefined' && typeof window.adiData.rgProduct !== 'undefined') {
    PRODUCT = window.adiData.rgProduct;
  } else {
    PRODUCT = 'undefined'
  }
  return PRODUCT;
}
PRODUCT = getProduct();

//Set product specific offsets - temp code until Mike can write this in a better way
let sleeperOffset = PLATFORM === "mobile" ? 130 : 100;

/**
 * Promise polyfill instantiation
 */
polyfill();

// start the refresh timer for ads
window.onload = () => {
  typeof window !== "undefined" && typeof window.AdManager !== "undefined" && window.AdManager.timedRefresh();
};

export const domain = `${affiliateData.domain.dom}.${affiliateData.domain.tld}`;
export const flags = {
  revgen_debug: qsc.revgen_debug === true,
  allAdsDisabled: disableAllAds(qsc),
  headerBiddingDisabled: disableAllHb(qsc),
  rubiconEnabled: checkRubiconFlags(domain),
  amznAdsEnabledViaConfig: checkAmznAdsEnabled(domain),
  amznAdsDisabledViaWindow: checkAmznAdsDisabled(),
};

/**
 * "Global" object that we can store variables in if we need to pass them between modules.
 * Global is in quotes because it can only be accessed from within revgen.  So its not even global in reality
 */
const globals = {
  config,
  initialized: false,
  domReady: false,
  fetchedAds: {},
  didAutoLoad: false,
  dataSourcesPromise: null,
  analyticData: {
    performance: {}
  }
};

/**
 * This holds all the instantiated revgen modules for us to use all over this file.
 */
const revgen = {
  data: null,
  log: null,
  time: null,
  gpt: null,
  api: null,
  ready: false
};

const createScripts = () => {
  if (!flags.allAdsDisabled) {
    createGPTScript();

    if (flags.headerBiddingDisabled) {
      return false;
    }

    if (flags.rubiconEnabled) {
      createRubiconScript(domain);
    }

    if (!flags.amznAdsDisabledViaWindow && flags.amznAdsEnabledViaConfig) {
      createAmazonScript(adLogger);

      // set Adserver RED-4062
      apstag.init({
        pubID: '3178',
        adServer: 'googletag',
      });
      adLogger("initAmazonScript", `Initializing Amazon Script (AdServer)`, "INFO");
    }
  }
}

(function () {
  // setting a timeout of 3000 ms to wait for gpp to fully load - RED-4159
  let haveScriptsLoaded = 'false';

  const gppTimeout = setTimeout(() => {
    adLogger("gppTimeout", "Gpp api is taking longer than expected, creating ad scripts", "WARN");
    createScripts();
    haveScriptsLoaded = 'true';
  }, 3000);

  window.addEventListener('gpp:fullyReady', () => {
    clearTimeout(gppTimeout);
    adLogger("gpp:fullyReady", "gpp is ready, timeout cleared, creating ad scripts", "INFO");
    AdManager.gppFullyReady = true;

    if (haveScriptsLoaded === 'false') {
      adLogger("gpp:fullyReady", "gpp ready and scripts have not been loaded yet", "INFO");
      createScripts();
    }
  });
})();

// setup pbjs ASAP
window.pbjs = window.pbjs || {};
window.pbjs.que = window.pbjs.que || [];

// setup the googletag ASAP
window.googletag = window.googletag || {};
window.googletag.cmd = window.googletag.cmd || [];

// set AdManager to ready and emit it.
window.addEventListener('gpt:ready', () => {
  AdManager.ready = true;
  window.dispatchEvent(new CustomEvent('ready'));
});

/**
 * Wrapper that contains static methods for the API
 * It's basically a wrapper that allows the api module to communicate and pass data to and from this module (and thus the other modules)
 * @class apiWrapper
 */
class apiWrapper {
  /**
   * injectUnit is an exposed privleged method for the apiWrapper
   * that serves as an abstraction to the gpt setSlots method
   * @param  {array} units an array of divs to display
   */
  static injectUnit(unit, config = {}, callback = null) {
    if (!unit.length) {
      adLogger("injectUnit", "Inject unit was called without any divs", "LOGGERV1");
      return;
    }

    let i = 0;
    let len = unit.length;

    for (i; i < len; i++) {
      adLogger("injectUnit", `Inject unit called for ${unit[i].divId}`, "LOGGERV1");
      unit[i] = mergeWith({}, unit[i], config);
    }

    if (!revgen.gpt.ready) {
      let str = "";
      for (i = 0; i < len; i++) {
        str += `${unit[i].divId} `;
        if (this.unitExists(unit[i].divId)) {
          revgen.gpt.refreshQueue.push(unit[i]);
        } else {
          revgen.gpt.injectQueue.push(unit[i]);
        }
      }
      str = str.trim();
      adLogger("injectUnit", `Inject unit called before GPT was ready.  Pushing ${str} to queueAdCall`, "LOGGERV1");
    } else {
      while (len--) {
        if (this.unitExists(unit[len].divId)) {
          revgen.gpt.refreshAds([unit[len]]);
          unit.splice(len, 1);
        }
      }
      // call setSlots on the array
      revgen.gpt.setSlots(unit);
    }
    if (callback) {
      callback();
    }
  }

  static refreshUnit(unit, callback) {
    if (!unit.length) {
      adLogger("refreshUnit", "refreshUnit was called without any divs", "LOGGERV1");
      return;
    }

    const adUnits = revgen.gpt.adUnits;

    // check if refresh unit received a div id as an array element
    // if so, look it up in the units queue and refresh
    if (adUnits[unit]) {
      unit = adUnits[unit].slot;
    }

    // change the ad type to refreshed for reporting
    if (unit.slotMap) {
      unit.slotMap.adType = "refreshed";
    }

    if (!revgen.gpt.ready) {
      adLogger("refreshUnit", `Refresh unit called before GPT was ready.  Pushing ${unit.divId
      } to queueAdCall`, "LOGGERV1");
      if (Array.isArray(unit)) {
        for (let i = 0; i < unit.length; i++) {
          revgen.gpt.refreshQueue.push(unit[i]);
        }
      } else {
        revgen.gpt.refreshQueue.push(unit);
      }
    } else {
      revgen.gpt.refreshAds([unit]);
    }
    if (callback) {
      callback();
    }
  }

  static refreshViewableUnits() {
    const ads = revgen.gpt.AdUnits;
    let units = [];

    units = Object.keys(ads).reduce((arr, key) => {
      const container = window.document.getElementById(key);
      return container && isInViewport(container)
        ? [].concat(arr, { divId: key })
        : arr;
    }, []);

    revgen.gpt.refreshAds(units);
  }

  // REVGEN-789 - setting up a new timed refresh for our ad units
  static timedRefresh() {
    const timedRefreshDisabled = "refresh" in qsc && qsc.refresh === "0"; // check qs for ability to disable refresh
    adLogger("TimedRefresh", `Is TimedRefresh disabled? ${timedRefreshDisabled}`, "INFO");

    if (!timedRefreshDisabled) {
      // check for refresh or not
      setInterval(() => {
        // if yes, refresh every 1 second for the timer feature for each ad slot
        const ads = revgen.gpt.AdUnits;
        const keys = Object.keys(ads);

        keys.forEach(key => {
          // setup our adRefreshData object
          adRefreshData[key] ? null : (adRefreshData[key] = {});
          adRefreshData[key].rg_refresh_counter ||
            adRefreshData[key].rg_refresh_counter >= 0
            ? null
            : (adRefreshData[key].rg_refresh_counter = 0);

          adRefreshData[key].slotName || adRefreshData[key].slotName === ""
            ? null
            : (adRefreshData[key].slotName = ads[key].slot.slotName);

          adRefreshData[key].timedRefreshOn || adRefreshData[key].timedRefreshOn === false
            ? null
            : (adRefreshData[key].timedRefreshOn = false);

          adRefreshData[key].inView || adRefreshData[key].inView === ""
            ? null
            : (adRefreshData[key].inView = false);

          adRefreshData[key].timer || adRefreshData[key].timer >= 0
            ? null
            : (adRefreshData[key].timer = 0);

          adRefreshData[key].mouseOnTop ||
            adRefreshData[key].mouseOnTop === false
            ? null
            : (adRefreshData[key].mouseOnTop = false);

          adRefreshData[key].overrideTime ||
            adRefreshData[key].overrideTime >= 0
            ? null
            : (adRefreshData[key].overrideTime =
              dfpSlotMap[PLATFORM][ads[key].slot.slotName].refresh_count);

          adRefreshData[key].overrideRate ||
            adRefreshData[key].overrideRate >= 0
            ? null
            : (adRefreshData[key].overrideRate =
              dfpSlotMap[PLATFORM][ads[key].slot.slotName].refresh_rate);
        });

        let unitsToRefresh = [];
        unitsToRefresh = Object.keys(ads).reduce((arr, key) => {
          // take ads and simplify so we have only the adUnit and divId in an array
          const container = window.document.getElementById(key);
          return container ? [].concat(arr, { divId: key }) : arr;
        }, []);

        unitsToRefresh.forEach(unit => {
          // we check if mouse is over ad, if it is, we don't allow the ad's timer to increment which never allows it to refresh
          const div = document.getElementById(unit.divId);
          div.onmouseover = () => {
            adRefreshData[unit.divId].mouseOnTop = true;
            adLogger("TimedRefresh", `Mouse is on top of the ad ${unit.divId} - timer will stop`, "INFO");
          };
          div.onmouseout = () => {
            adRefreshData[unit.divId].mouseOnTop = false;
            adLogger("TimedRefresh", `Mouse has left the top of the ad ${unit.divId} - timer will resume`, "INFO");
          };
          if (!adRefreshData[unit.divId].mouseOnTop) {
            adRefreshData[unit.divId].timer =
              adRefreshData[unit.divId].timer + 1;
          }
        });

        for (let i = 0; i < unitsToRefresh.length; i++) {
          // if ad has not reached the counter limit for refreshes and is on it's refresh cycle, refresh and increment the refresh counter.
          let refreshInterval;
          let timesToRefresh;

          PRODUCT = getProduct(); // update the product variable before calling new ads

          // if no settings are added to the adslot
          if (
            typeof adRefreshData[unitsToRefresh[i].divId].overrideTime !==
            "undefined"
          ) {
            if (
              (adRefreshData[unitsToRefresh[i].divId].overrideTime[PRODUCT] &&
                typeof adRefreshData[unitsToRefresh[i].divId].overrideTime
                  .all === "undefined") ||
              adRefreshData[unitsToRefresh[i].divId].overrideTime[PRODUCT] === 0
            ) {
              timesToRefresh =
                adRefreshData[unitsToRefresh[i].divId].overrideTime[PRODUCT];
            } else if (
              (typeof adRefreshData[unitsToRefresh[i].divId].overrideTime[
                PRODUCT
              ] === "undefined" &&
                adRefreshData[unitsToRefresh[i].divId].overrideTime.all) ||
              adRefreshData[unitsToRefresh[i].divId].overrideTime.all === 0
            ) {
              // check for all key
              timesToRefresh =
                adRefreshData[unitsToRefresh[i].divId].overrideTime.all;
            } else {
              timesToRefresh = 10; // default of 10 refreshes
            }
          } else {
            timesToRefresh = 10; // default
          }

          // if no settings are added to the adslot
          if (
            typeof adRefreshData[unitsToRefresh[i].divId].overrideRate !==
            "undefined"
          ) {
            if (
              (adRefreshData[unitsToRefresh[i].divId].overrideRate[PRODUCT] &&
                typeof adRefreshData[unitsToRefresh[i].divId].overrideRate
                  .all === "undefined") ||
              adRefreshData[unitsToRefresh[i].divId].overrideRate[PRODUCT] === 0
            ) {
              refreshInterval =
                adRefreshData[unitsToRefresh[i].divId].overrideRate[PRODUCT];
            } else if (
              (typeof adRefreshData[unitsToRefresh[i].divId].overrideRate[
                PRODUCT
              ] === "undefined" &&
                adRefreshData[unitsToRefresh[i].divId].overrideRate.all) ||
              adRefreshData[unitsToRefresh[i].divId].overrideRate.all === 0
            ) {
              // check for all key
              refreshInterval =
                adRefreshData[unitsToRefresh[i].divId].overrideRate.all;
            } else {
              refreshInterval = 30; // default of 30 sec
            }
          } else {
            refreshInterval = 30; // default
          }

          // if timedRefreshOn is true, we will send the extra key values in GPT to analytics - REVGEN-833
          if (timesToRefresh !== 0 && refreshInterval !== 0) {
            adRefreshData[unitsToRefresh[i].divId].timedRefreshOn = true;
          }

          if (
            adRefreshData[unitsToRefresh[i].divId].rg_refresh_counter <
            timesToRefresh &&
            adRefreshData[unitsToRefresh[i].divId].inView === true && // adding if it's in view which is if over 50% in gpt.js
            adRefreshData[unitsToRefresh[i].divId].timer % refreshInterval === 0
          ) {
            window.AdManager.refreshUnit(unitsToRefresh[i].divId);
            adRefreshData[unitsToRefresh[i].divId].rg_refresh_counter =
              adRefreshData[unitsToRefresh[i].divId].rg_refresh_counter + 1;

            adLogger("TimedRefresh", `AdRefreshData object is set with these adUnits on the page - `, "INFO");
            adLogger("TimedRefresh", adRefreshData, "DATA");
            adLogger("TimedRefresh", `We just refreshed this ad - ${unitsToRefresh[i].divId
              }`, "INFO");
            adLogger("TimedRefresh", `Ad ${unitsToRefresh[i].divId
              } has been refreshed ${adRefreshData[unitsToRefresh[i].divId].rg_refresh_counter
              } times`, "INFO");
            adLogger("TimedRefresh", `The refresh interval for ${unitsToRefresh[i].divId
              } is every ${refreshInterval} seconds`, "INFO");
            adLogger("TimedRefresh", `The maximum times to refresh for ${unitsToRefresh[i].divId
              } is capped at ${timesToRefresh}`, "INFO");
          }
        }
      }, 1000);
    }
  }

  static unitExists(unit) {
    return Object.keys(revgen.gpt.AdUnits).indexOf(unit) >= 0;
  }

  static makeSmartAd(ad) {
    const returnData = {
      element: ad.container,
      parent: ad.container.parentNode,
      active: true
    };

    googletag.cmd.push(() => {
      const sleeper = Sleeper.create(ad.container, {
        context: document.body.classList.contains("modal_open")
          ? document.querySelector(".gallery_modal")
          : null,
        offset: sleeperOffset,
        triggerFirstInViewEvent: true,
        debug: !adDebug
      });

      sleeper.once("entering", event => {
        if (this.unitExists([ad.slotMap.divId])) {
          this.refreshUnit([ad.slotMap]);
        } else {
          this.injectUnit([ad.slotMap]);
        }
      });
    });

    return returnData;
  }

  static setContext(context) {
    const gpt = revgen.gpt || GPT;
    gpt.setContext(context);
  }

  static setHost(host) {
    globals.config.host = host;
  }

  static getValue(value) {
    let data = globals.analyticData[value];
    if (typeof data === "undefined") {
      try {
        data = window.localStorage.getItem(value);
        if (!data) {
          data = window.sessionStorage.getItem(`revgen${value}`);
        }
      } catch (e) {
        adLogger("getValue", "Local Storage is not available.", "LOGGERV1");
      }
    }
    return data;
  }

  static updateEmbeddedTags(newConfigObj) {
    if (!googletag.pubads) {    // updateEmbeddedTags throws an uncaught error if GPT.pubads does not exist.
      return false;
    }

    const configObj = newConfigObj || {};
    revgen.data.updateEmbeddedTags(configObj);
    if (typeof configObj.context !== "undefined" && configObj.context.length) {
      this.setContext(configObj.context);
    }
    revgen.gpt.setPageLevelData(revgen.data.getDataSources());
  }

  static updateGAMPageTargeting(GAMTargeting = {}) {
    // precaution in case GAM is not on the page
    window.googletag = window.googletag || {};
    window.googletag.cmd = window.googletag.cmd || [];

    for (const [key, value] of Object.entries(GAMTargeting)) {
      googletag.cmd.push(() => {
        googletag.pubads().setTargeting(key, value);
      });
    }
  }

  static updateContext(context) {
    if (!context) {
      return;
    }
    revgen.gpt.setContext(context);
  }

  static getContext() {
    return revgen.gpt.adUnitPath;
  }

  static dumpState() {
    return revgen.gpt.adUnitsLoaded;
  }
}

export const createAPI = () => new API(apiWrapper, globals.config, domain);

/** END WRAPPER  **/

/**
 * Initializes Revgen and sets the config.
 * @param {object}  config - The config object.
 * @param {boolean} override - Boolean value that determines if we put it in the window scope or not.
 * @returns {object|function} - Returns function is no config is passed (assume we are being required) or returns the API.
 */
const init = newConfig => {
  // If revgen has already initialized exit and return the api
  if (globals.initialized) {
    adLogger("init", "Revgen already initialized; returning API", "LOGGERV1");
    return revgen.api;
  }

  adLogger("init", "Revgen Initialization Start", "LOGGERV1");

  // prehoist up all the variables for readability
  let dataInitPromise = new Promise(resolve => resolve()); // auto resolved promise as a fallback for when header bidding is disabled

  // clear the revgen local storage.
  if (
    typeof window.localStorage !== "undefined" &&
    window.location.pathname.indexOf("/adabuse") < 0
  ) {
    if (window.localStorage.getItem("revgen/store")) {
      window.localStorage.removeItem("revgen/store");
    }
  }

  // Set config in the global object and merge it with newConfig passed in during initialization
  // This is a module dependency injection used currently by OAP
  // E.g. AdManager.initizalize(config)  config will be merged with globals.config and assigned to
  // a new object.
  globals.config = addToConfig(assign({}, globals.config, newConfig));

  // Set the adsEnabled property if we want to enable/disable all ads
  globals.config.adsEnabled = checkAdsEnabled(globals.config.adsEnabled);

  // if ads are disabled, turn off lotame lightning
  if (globals.config.adsEnabled) {
    runLotameLightningScripts();
    createLotameLightningScript();
  }

  // if ads are disabled exit
  if (!globals.config.adsEnabled) {
    adLogger("init", "Ads are disabled. Exiting Revgen", "LOGGERV1");
    return;
  }

  // Set the enableHeaderBidding property
  globals.config.enableHeaderBidding = checkHeaderBidding(
    globals.config.enableHeaderBidding
  );

  // if ads enabled begin injecting third party scripts
  if (globals.config.enableHeaderBidding) {
    adLogger("init", "Injecting Header bidding", "LOGGERV1");
    // options get merged via this line: addToConfig(assign({}, globals.config, newConfig));
  }

  // Set the platform variable
  globals.config.platform = getPlatform();

  // instantiate the GPT module
  revgen.gpt = new GPT(globals.config);

  // Construct the datasources class and recieve the promise on init
  revgen.data = new DataSources(window, globals.config);
  dataInitPromise = revgen.data.init() || dataInitPromise;

  // if the dataInit promise resolved, initialize the gpt module.
  // there's a bottleneck here on the embedded tags which waits for dom ready
  globals.dataSourcesPromise = dataInitPromise.then(
    values => {
      adLogger("init", "DATA SOURCES RESOLVED && GPT READY", "LOGGERV1");
      const sources = values;
      const dataSources = revgen.data.getDataSources();

      revgen.gpt.init(dataSources);

      if (Object.keys(sources).length) {
        // setup the analyticData
        globals.analyticData = mergeWith({}, globals.analyticData, {
          rg_product: sources.embeddedTags.data.rg_product,
          rg_pagetype: sources.embeddedTags.data.rg_pagetype
        });
        adLogger("init", "Sources received", "LOGGERV1");
      } else {
        adLogger("init", "No sources received", "LOGGERV1");
      }
    }
  );

  /**
   * Going with something a little more solid for domReady
   */
  domready(() => {
    adLogger("init", "DOM Ready", "LOGGERV1");

    globals.domReady = true;

    // TODO: do we need this?
    addToConfig(getScript("revgen", true));

    // store the debuggerLinkEnabled in case we want to reuse it.
    globals.config.debuggerLinkEnabled =
      document.cookie.indexOf("adv_ads_debugger") > -1;

    // if the debugger link is enabled add a link to the page.
    if (globals.config.debuggerLinkEnabled) {
      setDebuggerLink();
    }
  });

  revgen.api = createAPI();

  globals.initialized = true;

  // If we are debugging, put the globals + modules in the window scope for easy access.
  if (flags.revgen_debug) {
    adLogger("Flags", `${JSON.stringify(flags, null, 4)}`, "INFO");
    revgen.flags = flags;
    window.globals = globals;
    window.revgen = revgen;
  }

  return revgen.api;
};

/**
 * Main export that will be used to initialize this file.
 * @param {object} newConfig - The new config to use
 */
const initialize = newConfig => init(newConfig || config);
export default { initialize };
