/**
 * file target.js
 * @author Constantinos Davanos
 */
/**
 * The 'interface' for datasource types. Each Data Source should have an init that returns a
 * promise resolving with its data and a getDataSources method for getting the data for each source
 */
export default class Target {
  constructor(window) {
    if (!window || typeof window !== 'object') {
      throw new TypeError('Target: Window must be an object.');
    }

    this.window = window;
    this.data = {};
    this.initialized = false;
  }

  get() {
    if (!this.initialized) {
      throw new Error('This instance has not been initialized yet.');
    }
  }
}

  /**
  * Curtails the amount of data that can be sent to Google.
  */
  export function getDataSources() {
    const keys = Object.keys(this.data);
    const returnData = {};
    if (!keys.length) {
      return;
    }

    for (let i = 0; i < keys.length; i++) {
      if (Array.isArray(this.data[keys[i]]) && this.data[keys[i]].length > 400) {
        const len = this.data[keys[i]].length - (this.data[keys[i]].length - 400);
        returnData[keys[i]] = this.data[keys[i]].splice(-len);
      } else {
        returnData[keys[i]] = this.data[keys[i]];
      }
    }

    return Object.keys(returnData).length ? returnData : null;
  }
