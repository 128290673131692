import {adLogger} from "../loggerV2";
import {sendLotamePrivacyValue} from "./lotameLightning";

export const privacyMap = {
  'usnat': {
    'geo': 'National',
    'geoQueryString': '',
    'consentOptIns': {
      'SaleOptOut': 2,                // opt in
      'SharingOptOut': 2,             // opt in
      'TargetedAdvertisingOptOut': 2, // opt in
      'Gpc': false                    // opt in
    },
  },
  'usca': {
    'geo': 'California',
    'geoQueryString': 'otgeo=us,ca',
    'consentOptIns': {
      'SaleOptOut': 2,               // opt in
      'SharingOptOut': 2,            // opt in
      'Gpc': false                   // opt in
    },
  },
  'usco': {
    'geo': 'Colorado',
    'geoQueryString': 'otgeo=us,co',
    'consentOptIns': {
      'SaleOptOut': 2,                       // opt in
      'TargetedAdvertisingOptOut': 2,        // opt in
      'Gpc': false                           // opt in
    },
  },
  'usct': {
    'geo': 'Connecticut',
    'geoQueryString': 'otgeo=us,ct',
    'consentOptIns': {
      'SaleOptOut': 2,                       // opt in
      'TargetedAdvertisingOptOut': 2,        // opt in
      'Gpc': false                           // opt in
    },
  },
  'usut': {
    'geo': 'Utah',
    'geoQueryString': 'otgeo=us,ut',
    'consentOptIns': {
      'SaleOptOut': 2,                       // opt in
      'TargetedAdvertisingOptOut': 2,        // opt in
    },
  },
  'usva': {
    'geo': 'Virginia',
    'geoQueryString': 'otgeo=us,va',
    'consentOptIns': {
      'SaleOptOut': 2,                       // opt in
      'TargetedAdvertisingOptOut': 2,        // opt in
    },
  },
}

const oneTrust = {
  data: {},
  init: () => {
    const localParsedGppValue = JSON.parse(getLocalGppParsedValue());
    adLogger("GPP", `We have a local parsed GPP value - ${JSON.stringify(localParsedGppValue)}`, "INFO");

    // GPP is not on the page right away and must we waited on
    const gppCheck = setInterval(() => {
      adLogger("gppCheck", `Waiting for the gpp api`, "INFO");
      if (typeof __gpp !== 'undefined') {
        window.dispatchEvent(new CustomEvent('gpp:ready'));
        clearInterval(gppCheck);
        if (localParsedGppValue === null) { // we don't have a local value
          adLogger("gppCheck", `We do not have a local gpp value`, "INFO");
          adLogger("gppCheck", `clearing gpp interval`, "INFO");
          checkGppApi('new');
        } else {
          adLogger("gppCheck", `We DO have a local gpp value`, "INFO");
          sendPrivacySettings (localParsedGppValue)
          // send the local data but check for updated data
          checkGppApi('update');
        }
      }
    }, 100);

    setTimeout(() => {
      adLogger("gppCheck", `clearing gpp interval`, "INFO");
      clearInterval(gppCheck);
    }, 2900); // 2.9 second timeout to make sure we're not checking for gpp forever - we will build ad scripts at 3 seconds
  }
};

export const checkGppApi = (valueType) => {
  // when gpp arrives on the page, the parsed value is not there yet!
  adLogger("checkGppApi", `Waiting for the gpp api events`, "INFO");
  __gpp('addEventListener', function (evt) {
    //callback will receive all events, we only want to react on signalStatus ready events
    if (
      !["listenerRegistered", "signalStatus"].includes(evt.eventName) ||
      evt.pingData.signalStatus !== "ready"
    ) { return; }

    window.dispatchEvent(new CustomEvent('gpp:fullyReady'));

    const apiGppParsedValue = getGppParsedValue(evt);
    // if we try to send updated values on the same pv, there can be a timing issue where the new value is sent before the fallback so save the value for next pv
    if (valueType === 'new') {
      sendPrivacySettings(apiGppParsedValue);
    }
  })
}

export function getGppParsedValue(evt) {
  adLogger("getGppParsedValue", `Checking gpp for api parsed value`, "INFO");
  const gppParsedSections = evt.pingData.parsedSections;
  adLogger("getGppParsedValue", `saving the new or updated gpp object in localStorage: ${JSON.stringify(gppParsedSections)}`, "INFO");
  localStorage.setItem('parsedGppCookie', JSON.stringify(gppParsedSections));
  return gppParsedSections;
}

export function getLocalGppParsedValue() {
  // local will have a value or null
  const GppParsed = localStorage.getItem('parsedGppCookie');
  adLogger("getLocalGppParsedValue", `Checking for localStorage parsed value - ${GppParsed}`, "INFO");
  return GppParsed;
}

// Propagate the privacy settings to GPT & Lotame, a9 must be instantiated.
export function sendPrivacySettings (privacyObject) {
  adLogger("sendGppPrivacySettings", `raw privacy object: ${JSON.stringify(privacyObject)}`, "INFO");
  const gppOptStatus = parseGppCookieObject(privacyObject);
  adLogger("sendGppPrivacySettings", `sendGppPrivacySettings to gpt & Lotame ~ privacyObject: ${gppOptStatus}`, "INFO");
  // precaution in case GAM is not on the page // google isn't ready
  window.googletag = window.googletag || {};
  window.googletag.cmd = window.googletag.cmd || [];

  googletag.cmd.push(() => {
    googletag.pubads().setTargeting('rg_gpp', gppOptStatus);
  });
  sendLotamePrivacyValue(gppOptStatus);
}

export function parseGppCookieObject (privacyObject) {
  /*
      We need to read all of the below fields for each section a user has in their consent string
      if any single one contains the following set as an opt-out, we set the distilled value to “true”, otherwise their value is “false”
      - The above was reversed in RED-4364, originally opposite in RED-3426

      SaleOptOut. (1 means opt out, 2 is opted in)
      SharingOptOut (1 means opt out, 2 is opted in)
      TargetedAdvertisingOptOut (1 means opt out, 2 is opted in)
      Gpc  (if value equals true the user is opted out)

      0 is also treated as an opt in
  */

  let privacyValue = 'false';

  if (!privacyObject) {
    // default to false
    return 'false';
  }

  // finalize the privacy value
  privacyValue = parseParsedCookie(privacyObject);

  adLogger("parseGppCookieObject", `the gpp opt in / out value is: ${privacyValue === 'false' ? `${privacyValue} - opt in` : `${privacyValue} - opt out`}`, "INFO");
  return privacyValue //true (opted out) or false (opted in)
}

export function parseParsedCookie(privacyObject) {
  let countTrue = 0;
  // grab keys as there could be multiple geos
  const privacyObjectKeys = Object.keys(privacyObject);
  let privacyStatus = 'false';
  // check for opt in or opt out
  privacyObjectKeys.forEach(key => {
    const trimmedKey = key.replace(/v[1-9]/g,'');
    const detailsToCheckKeys = Object.keys(privacyMap[trimmedKey]['consentOptIns']);
    detailsToCheckKeys.forEach((mapKey) => {
      if (privacyMap[trimmedKey]['consentOptIns'][mapKey] === privacyObject[key][mapKey] || privacyObject[key][mapKey] === 0) {
        privacyStatus = 'false';
      } else {
        privacyStatus = 'true';
        countTrue++;
      }
    })
    return privacyStatus;
  });
  if (countTrue > 0) {
    privacyStatus = 'true';
  }
  return privacyStatus;
}

export default oneTrust;
