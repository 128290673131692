import { adLogger } from "../loggerV2";

export const initDcr = () => {
  const dcrTargetValue = getDcrTargetString();
  if (dcrTargetValue) {
    adLogger("dcr - initDcr", `Got dcr test target value: ${dcrTargetValue}`, "INFO");
    setTargeting('rg_dcr-seg', dcrTargetValue);
  } else {
    adLogger("dcr - initDcr", `No dcr test cookie found. Will NOT set dcr test target value.`, "INFO");
  }
}

export function setTargeting(targetKey, targetValue) {
  adLogger("dcr - setTargeting", `${targetKey} targeting will be set now to ${targetValue}`, "INFO");
  googletag.pubads().setTargeting(targetKey, targetValue);
}

export const getDcrTargetString = () => {
  const dcrCookieRegex = /(^|\s*)_pc_test_(\w+)\=([^;]+)(;|$)/g;
  let dcrTargetString = '';
  let match = null;
  do {
    match = dcrCookieRegex.exec(document.cookie);
    if (match) {
      adLogger("dcr - getDcrTargetString", `Found dcr test cookie _pc_test_${match[2]}, value: ${match[3]}`, "INFO");
      dcrTargetString = dcrTargetString + match[2] + '_' + match[3] + ',';
    }
  } while (match);

  dcrTargetString = dcrTargetString.replace(/,$/, ""); //Remove trailing comma
  return dcrTargetString;
}