// deps
import { waitFor } from './utils';
import { adLogger } from './loggerV2';

/**
 * [Gallery description]
 */

class Gallery {

  constructor(gpt = null, opts = {}) {
    this.gpt = gpt;

    // ensure body exists
    waitFor(() => document.body, () => {
      this.mutations = this.observeMutations();
    });

  }

  observeMutations() {
    const observer = new MutationObserver(this.onModalStateChange.bind(this));
    observer.observe(document.body, { attributes: true });
    return observer;
  }

  /**
   * onModalStateChange is the  event handler for the gallery modal event.
   * @param  {object} event the event data associated with the gallery modal
   */
  onModalStateChange(entries) {
    // pluck the entry
    const entry = entries.find((entry) => {
      return (entry.target === document.body && entry.attributeName === 'class');
    });

    adLogger("onModalStateChange", `entry: ${JSON.stringify(entry)}`, "LOGGERV1");

    if (!entry) {
      return false;
    }

    // set the modal to open if so
    // otherwise deactivate the slots
    if (entry.target.classList.contains('modal_open')) {
      adLogger("onModalStateChange", `setting modal to active`, "LOGGERV1");
      this.gpt.modalActive = true;
    }
    else {
      adLogger("onModalStateChange", `deactivating modal slots`, "LOGGERV1");
      this.gpt.deactivateModalSlots();
      this.gpt.modalActive = false;
      this.gpt.modalSlotCounter = {};
      adLogger("onModalStateChange", `this.gpt.modalActive: ${this.gpt.modalActive}`, "LOGGERV1");
      adLogger("onModalStateChange", `this.gpt.modalSlotCounter: ${JSON.stringify(this.gpt.modalSlotCounter)}`, "LOGGERV1");
    }
  }

}

export default Gallery;
