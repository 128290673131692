// this will be tested mainly in cypress - authsource cookie should be set no matter what on every page
import { getCookie, pathValue, isFunctionCallFirst } from '../utils';
import { adLogger } from "../loggerV2";

export const authOptions = {
  subscriber: 'true_sub',
  registration: 'true_reg',
  emailHash: 'true_email',
  wall: 'true_wall',
  other: 'true_other',
  false: 'false',
};

export const cookieValues = Object.keys(authOptions);
export const targetingValues = Object.values(authOptions);
export const authsourceOrigin = 'authsource_origin';

export const initAuthSource = () => {
  // if there is a cookie, get that value and setTargeting if it is available now!
  const authCookieVal = initialCookieCheck('authsource');
  const originalAuthCookieVal = initialCookieCheck(authsourceOrigin);
  let rg_idl = getRgIDL(originalAuthCookieVal);        // rg_idl is mapped to the authsource cookie's value and is used for ad targeting.
  adLogger("authSource - initAuthSource", `initial values -  rg_idl for targeting = ${rg_idl}, derived from ${authsourceOrigin} cookie value of ${originalAuthCookieVal}`, "INFO");
  // Don't wait for BlueConic to set targeting right, base targeting on localStorage data or GTM.
  // If GTM has loaded profile property values prior to Revgen being loaded, bcProfileProps will be set by GTM.
  if (typeof window !== 'undefined' && window.bcProfileProps) {
    adLogger("authSource - initAuthSource", 'Revgen loaded after BlueConic data loaded; set auth', "INFO");
    var originalIDLFlag = false;
    if (typeof window.originalIDL !== 'undefined') {
      originalIDLFlag = window.originalIDL;
    }
    rg_idl = updateCookieAndTargeting(rg_idl, window.bcProfileProps, originalIDLFlag);
  } else {
    rg_idl = updateCookieAndTargeting(rg_idl, null, null);
  }

  adLogger("authSource - initAuthSource", 'Wait for BlueConic profile properties', "INFO");
  // we don't have the below in the CDP module yet - kg
  document.addEventListener('bcProfileLoaded', function (e) {
    adLogger("authSource - initAuthSource", `Received BlueConic profile properties ${JSON.stringify(e.detail)}`, "INFO");
    if (pathValue(e, ['detail', 'blueConicProps'])) {
      let isOriginalIDL = false;
      if (pathValue(e, ['detail', 'originalIDL'], false)) {
        isOriginalIDL = true;
      }
      rg_idl = updateCookieAndTargeting(rg_idl, e.detail.blueConicProps, isOriginalIDL);
    };
  });


};

export const computeAuthCookieValue = (bcProfileProps) => {
  const isAuth0EmailSet = bcProfileProps ? bcProfileProps.auth0Email : false;
  const isSailthruHashBitSet = bcProfileProps ? bcProfileProps.hashedEmail : false;
  const isWallBitSet = bcProfileProps ? bcProfileProps.emailWall : false;
  // Check first if IDL exists in localstorage (ATS) else, check in Prebid
  let hasIdl = false;
  if (!hasIdl && typeof localStorage !== 'undefined') {
    const atsIDL = localStorage.getItem('_lr_env');
    hasIdl = atsIDL ? true : false;
    adLogger('authSource - computeAuthCookieValue', `LocalStorage has IDL = ${!!hasIdl}`, 'INFO')
  }
  if (!hasIdl && typeof window !== 'undefined' && pathValue(window, ['pbjs', 'getUserIds'])) {
    const prebidIDL = window.pbjs.getUserIds().idl_env;
    hasIdl = prebidIDL ? true : false;
    adLogger('authSource - computeAuthCookieValue', `Prebid has IDL = ${!!hasIdl}`, 'INFO')
  }

  adLogger("authSource - computeAuthCookieValue", `Is hash bit set from Sailthru = ${!!isSailthruHashBitSet}. Is the email wall bit set = ${!!isWallBitSet}.`, "INFO");

  const caleCookie = getCookie('cale_results');
  const hasCale = caleCookie && caleCookie.includes('true');
  let cookieValue = 'false';
  adLogger("authSource - computeAuthCookieValue", `Cale cookie is set = ${hasCale}`, "INFO");

  if (isSailthruHashBitSet && typeof localStorage !== 'undefined' && localStorage.getItem('pendingProfileUpdate')) {
    localStorage.removeItem('pendingProfileUpdate');
    adLogger("authSource - computeAuthCookieValue", `The BlueConic profile update is no longer pending`, "INFO");
  }

  if (hasIdl && localStorage !== 'undefined' && localStorage.getItem('pendingIDL')) {
    localStorage.removeItem('pendingIDL');
    adLogger("authSource - computeAuthCookieValue", `The ATS IDL is no longer pending`, "INFO");
  }

  // assign class of cookie
  if (hasIdl) {
    if (hasCale) {
      cookieValue = cookieValues[0];
    } else if (isAuth0EmailSet) {
      cookieValue = cookieValues[1];
    } else if (isSailthruHashBitSet) {
      cookieValue = cookieValues[2];
    } else if (isWallBitSet) {
      cookieValue = cookieValues[3];
    } else {
      cookieValue = cookieValues[4];
    }
  } else {
    cookieValue = cookieValues[cookieValues.length - 1]; // An IDL does not exist.
  }

  adLogger("authSource - computeAuthCookieValue", `The new proposed cookie value is - ${cookieValue}`, "INFO");
  return cookieValue;
};

export const updateCookieAndTargeting = isFunctionCallFirst((rg_idl, bcProfileProps, isOriginalIDL, firstRun) => {
  // current cookie
  const existingAuthTargetingValue = rg_idl ? rg_idl : 'false';
  const existingAuthCookieValue = getCookie('authsource');
  const existingIDLCookieValue = getCookie(authsourceOrigin) || false;
  // what the class of cookie is if set right now

  if (firstRun) {
    const rgIDL = getRgIDL(existingIDLCookieValue || 'false');

    setCookie(authsourceOrigin, existingIDLCookieValue || 'false')
    setTargeting('rg_idl', rgIDL);

    return rgIDL;
  } else {
    adLogger("authSource - updateCookieAndTargeting", `isOriginalIDL = ${!!isOriginalIDL}`, "INFO");
    const newCookieValue = computeAuthCookieValue(bcProfileProps);
    const existingTargetingPos = targetingValues.indexOf(existingAuthTargetingValue);
    const newCookiePos = cookieValues.indexOf(newCookieValue);
    adLogger("authSource - updateCookieAndTargeting", `newCookieValue = ${newCookieValue}, existingAuthTargetingValue = ${existingAuthTargetingValue}`, "INFO");
    adLogger("authSource - updateCookieAndTargeting", `newCookiePos = ${newCookiePos}, existingTargetingPos = ${existingTargetingPos}`, "INFO");

    if (isOriginalIDL) {
      if (!existingIDLCookieValue || existingIDLCookieValue == 'false') {
        const newRGIDL = getRgIDL(newCookieValue);
        adLogger("authSource - updateCookieAndTargeting", `Update original authsource to ${newCookieValue}, rg_idl target to ${newRGIDL}`, 'INFO');
        setCookie(authsourceOrigin, newCookieValue);
        setTargeting('rg_idl', newRGIDL);
      } else {
        adLogger("authSource - updateCookieAndTargeting", `Original authsource already set. Will NOT replace.`, "INFO");
      }
    } else {
      adLogger("authSource - updateCookieAndTargeting", 'Not original IDL.', 'INFO');
      const prebidIDL = typeof window !== 'undefined' && pathValue(window, ['pbjs', 'getUserIds']) ? window.pbjs.getUserIds().idl_env : false;
      if (existingAuthCookieValue && !prebidIDL && !existingIDLCookieValue) {
        //`authsource` is known but `authsource_origin` is not set
        adLogger("authSource - updateCookieAndTargeting", `Unknown original authsource. Update original authsource to ${`unknown`}, rg_idl target to ${`true_unknown`}`, 'INFO');
        setCookie(authsourceOrigin, 'unknown');
        setTargeting('rg_idl', 'true_unknown');
      } else if (prebidIDL && newCookieValue != 'false' && (!existingIDLCookieValue || existingIDLCookieValue == 'false')) {
        //Prebid IDL exists but both authsource AND original authsource is not set and unknown
        adLogger("authSource - updateCookieAndTargeting", `Both authsource(s) not set. Update original authsource to **other**, rg_idl target to **true_other**`, 'INFO');
        setCookie(authsourceOrigin, 'other');
        setTargeting('rg_idl', 'true_other');
      }
    }
    const currAuthOriginValue = getCookie(authsourceOrigin) || false;
    if (currAuthOriginValue === 'false' && currAuthOriginValue !== 'unknown'&& newCookiePos < existingTargetingPos) {
      const newRgIDL = getRgIDL(newCookieValue);
      // if the new cookie is higher priority (lower number), reset the cookie to the higher class and new 7 days
      adLogger("authSource - updateCookieAndTargeting", 'Performing auth data update.', 'INFO');
      setCookie(authsourceOrigin, newCookieValue);
      setTargeting('rg_idl', newRgIDL);

      return newRgIDL;
    } else {
      adLogger("authSource - updateCookieAndTargeting", 'Skipping auth data update.', 'INFO');

      return existingAuthTargetingValue;
    }
  }
});

export const initialCookieCheck = (cookieName) => {
  const cookieVal = getCookie(cookieName) || 'false';
  adLogger("authSource - initialCookieCheck", `initial value of cookie ${cookieName}=${cookieVal}`, "INFO");

  return cookieVal;
};

// translate the cookie class to what will get passed to GAM
export const getRgIDL = (cookieValue) => {
  const position = cookieValues.indexOf(cookieValue);
  adLogger("authSource - getRgIDL()", `cookieValue: ${cookieValue} | position: ${position} | targetValue: ${targetingValues[position]}`, "INFO");

  return position !== -1 ? targetingValues[position] : 'false';
};

export function setTargeting (targetKey, targetValue) {
  adLogger("authSource - setTargeting", `${targetKey} targeting will be set now to ${targetValue}`, "INFO");
  googletag.pubads().setTargeting(targetKey, targetValue);
}

export const setCookie = (cookieName, cookieValue) => {
  adLogger("authSource - setCookie", `${cookieName}:${cookieValue} we either do not have a cookie and are setting a new one or we are revising it to change the value or reset the time (new 7 days)`, "INFO");
  const days = 7;
  const date = new Date();
  const res = date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
  const expDate = new Date(res);
  document.cookie = `${cookieName}=${cookieValue}; expires=${expDate}; path=/`;

  return cookieValue;
};
