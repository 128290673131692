/**
 * @file sources.js
 * @author Ken Egbuna
 */
import mergeWith from 'lodash.mergewith';
import EmbeddedTags from './targeting/embeddedTags';
import oneTrust from './targeting/oneTrust';
import { getDataSources } from './targeting/target';

//import Lotame from './targeting/lotame';
import { config } from './config/config';

export default class DataSources {
  /**
  * Constructors a new DataSources instance
  * @param {Window} window The window object
  * @param {object} config Previously known as the CustomAdDataConfig object.
  */
  constructor(window, config) {
    // TODO:: add check for Window. Currently doesn't work with testing. e.g. !(window instanceof Window)
    if (!window || typeof window !== 'object') {
      throw new TypeError('Window must be an object. The window object to be precise.');
    }

    this.window = window;
    this.config = config;

    this.sources = {};
    this.sources.embeddedTags = new EmbeddedTags(window, config.sources.embeddedTags);
    this.sources.oneTrust = oneTrust;
  }

  /**
  * Starts up instances of all specified data sources. Throws an error if the source is not available
  * @returns {Promise} A resolved promise if all members resolve or rejected with a reason
  */
  init() {
    if (!this.config.adsEnabled) {
      return new Promise((resolve) => {
        resolve();
      });
    }
    this.initPromise = this.initPromise || new Promise((resolve, reject) => {
      const keys = Object.keys(this.sources);

      Promise.all(keys.map((value) => this.sources[value].init())).then(() => {
        resolve(this.sources);
      }, (err) => {
        reject(err);
      });
    });

    return this.initPromise;
  }

  updateEmbeddedTags(newConfigObj) {
    const embeddedTags = typeof newConfigObj.sources !== 'undefined' && newConfigObj.sources.embeddedTags !== 'undefined'
      ? newConfigObj.sources.embeddedTags : {};
    this.sources.embeddedTags = new EmbeddedTags(window, embeddedTags);
    this.sources.embeddedTags.init();
  }

  /**
  * Returns all the enabled initialized sources as a concated queryString
  */
  getDataSources() {
    const sourceKeys = Object.keys(this.sources);
    let dataObject = {};

    for (let i = 0; i < sourceKeys.length; i++) {
      const key = sourceKeys[i];
      const sourceData = getDataSources.call(this.sources[key]);

      if (sourceData) {
        dataObject = mergeWith({}, dataObject, sourceData);
      }
    }

    return dataObject || null;
  }
}
