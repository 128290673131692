import {
  injectScript,
} from "../utils";
import {
  addToConfig,
  affiliateData,
  biddableSizes,
  biddableSizesPrebidAddons,
  dfpMap,
  getPlatform,
} from "../config/config";
import {
  adLogger,
  verbose,
} from "../loggerV2";

// check ad shut off flags
export const disableAllAds = (qsc) => (qsc.noads || (typeof window !== 'undefined' && window.noads === 'true'));
export const disableAllHb = (qsc) => (qsc.dhb || (typeof window !== 'undefined' && window.disableHB === 'true'));

// flags need to be checked but also FB pages use a window.Rubicon flag
export const checkRubiconFlags = (domain) => {
  return !!(dfpMap[domain] &&
      dfpMap[domain].rubiconAdsEnabled === true &&
      (typeof window.Rubicon === 'undefined' || (typeof window.Rubicon !== 'undefined' && window.Rubicon !== false)));
};

export const checkAmznAdsDisabled = () => {
  return typeof window.Amazon !== "undefined" && !window.Amazon;
};

export const checkAmznAdsEnabled = (domain) => {
  return dfpMap[domain] && dfpMap[domain].amznAdsEnabled !== "undefined"
      ? dfpMap[domain].amznAdsEnabled
      : dfpMap.default.amznAdsEnabled;
};

export const createAmazonScript = (adLogger) => {
  /**
   * Apstag injection...code is provided by amazon as an iife
   */
  (function(a9, a, p, s, t, A, g) {
    adLogger("createAmazonScript", "Creating Amazon Script", "INFO");
    addToConfig({ apstagEnabled: true });
    if (a[a9]) return;
    function q(c, r) {
      a[a9]._Q.push([c, r]);
    }
    a[a9] = {
      init: function() {
        q("i", arguments);
      },
      fetchBids: function() {
        q("f", arguments);
      },
      setDisplayBids: function() {},
      targetingKeys: function() {
        return [];
      },
      _Q: []
    };
    A = p.createElement(s);
    A.async = !0;
    A.src = t;
    g = p.getElementsByTagName(s)[0];
    g.parentNode.insertBefore(A, g);
  })(
    "apstag",
    window,
    document,
    "script",
    "//c.amazon-adsystem.com/aax2/apstag.js"
  );
};

/**
 * createGPTScript creates and adds the gpt script to the header of each document.
 */
export const createGPTScript = () => {
  adLogger("createGPTScript", "Creating GPT Script", "INFO");
  const id = "gpt-script";
  const src = "https://securepubads.g.doubleclick.net/tag/js/gpt.js";
  return injectScript(src, {}, id, true);
};

// createRubiconScript creates and adds the dynamic rubicon script to the head of each document.
export const createRubiconScript = (domain) => {
  adLogger("createRubiconScript", "Creating Rubicon Script", "INFO");
  const id = 'rubicon-script';
  const affiliateExists = dfpMap[domain] !== "undefined";
  if (affiliateExists && dfpMap[domain].rubiconAdsEnabled) {
    const src = '//micro.rubiconproject.com/prebid/dynamic/10071.js';
    return injectScript(src, {}, id, true);
  }
};

export const callForBids = (qsc, apstagSlots, demandManagerSlots) => {
  var bidTimeout = 1500;
  if (verbose) {
    adLogger("callForBids", `Timeout = ${bidTimeout}`, "INFO");
  }
  fetchHeaderBids(qsc, apstagSlots, demandManagerSlots, bidTimeout);
}

const fetchHeaderBids = (qsc, apstagSlots, demandManagerSlots, bidTimeout) => {
  var bidders = ["a9", "prebid"];

  var requestManager = {
    adserverRequestSent: false
  };
  // CREATE REQUESTMANAGER OBJECT; SET VALUES TO FALSE SIGNALING THAT BIDS HAVE NOT BEEN RETURNED YET
  bidders.forEach(function(bidder) {
    requestManager[bidder] = false;
  });

  const filteredDemandManagerSlots = requestBids(qsc, apstagSlots, demandManagerSlots, bidTimeout, requestManager, bidders);

  // TIMEOUT TO CALL ADSERVER IF ALL BIDS ARE NOT RETURNED IN CERTAIN TIMEFRAME
  window.setTimeout(function() {
    sendAdserverRequest(requestManager, filteredDemandManagerSlots, qsc);
  }, bidTimeout);
};

export const checkForSizes = (bidder, slot) => {
  let newSizes = [];
  let originalSizes = [];
  if (bidder === 'a9') {
    slot.sizes.forEach((size) => {
      originalSizes.push(size);
      biddableSizes.forEach((biddableSize) => {
        if (JSON.stringify(size) === JSON.stringify(biddableSize)) {
          newSizes.push(size);
        }
      });
    });
    slot.originalSizes = originalSizes; // object keeps data by reference - keeping original slot sizes
    slot.biddableSizes = newSizes; // this should be the slot sizes if A9
    return slot;
  }
  if (bidder === 'prebid') {
    slot.slotMap.sizes.forEach((size) => {
      originalSizes.push(size);
      biddableSizes.forEach((biddableSize) => {
        if (JSON.stringify(size) === JSON.stringify(biddableSize)) {
          newSizes.push(size);
        }
      });

      biddableSizesPrebidAddons.forEach((addonSize) => {
        if (JSON.stringify(size) === JSON.stringify(addonSize)) {
          newSizes.push(size);
        }
      });
    });
    slot.slotMap.originalSizes = originalSizes; // object keeps data by reference - keeping original slot sizes
    slot.slotMap.biddableSizes = newSizes; // this should be the slot sizes if A9
    return slot;
  }
};

export const checkForBiddableSizes = (apstagSlots, demandManagerSlots) => {
  apstagSlots.forEach((apstagSlot) => {
    adLogger("checkForBiddableSizes", `Checking A9 ads for biddable sizes - ${apstagSlot.slotID}`, "INFO");
    checkForSizes("a9", apstagSlot);            // This updates a9 ads by reference.
    if (apstagSlot.biddableSizes === []) {
      return;
    } else {
      apstagSlot.sizes = apstagSlot.biddableSizes;
    }
  });

  demandManagerSlots.forEach((demandManagerSlot) => {
    adLogger("checkForBiddableSizes", `Checking DemandManager ads for biddable sizes ${demandManagerSlot.divId}`, "INFO");
    checkForSizes("prebid", demandManagerSlot); // This updates the actual Google ad slots be reference.
  });
};

export const requestBids = (qsc, apstagSlots, demandManagerSlots, bidTimeout, requestManager, bidders) => {
  checkForBiddableSizes(apstagSlots, demandManagerSlots);

  const filteredApsTagSlots = [];
  const filteredDemandManagerSlots = [];

  // Check for biddableSizes and delete any adUnit that is not biddable
  apstagSlots.forEach(A9slot => {
    if (A9slot.biddableSizes.length) {
      filteredApsTagSlots.push(A9slot);
    }
  });

  // Check for biddableSizes and delete any adUnit that is not biddable
  demandManagerSlots.forEach(demandManagerSlot => {
    if (demandManagerSlot.slotMap.biddableSizes.length) {
      filteredDemandManagerSlots.push(demandManagerSlot);
    }
  });

  // REQUEST BIDS FROM AMAZON
  if (filteredApsTagSlots.length && typeof window.apstag !== 'undefined') {
    apstag.fetchBids(
      {
        slots: filteredApsTagSlots,
        timeout: bidTimeout
      },
      function(bids) {
        setA9TargetingRTB(bids, filteredDemandManagerSlots);
        headerBidderBack("a9", requestManager, bidders, filteredDemandManagerSlots, qsc);
      }
    );
  }

  // REQUEST BIDS FROM DEMAND MANAGER
  // window.Rubicon - false means it's off
  const rubiconOff = (typeof window !== 'undefined' && window.Rubicon === false) || disableAllHb(qsc) === true ? true : false;
  if (filteredDemandManagerSlots.length > 0 && rubiconOff !== true) {
  adLogger("requestBids", `Requesting bids for - [SlotData] below - `, "INFO");
  adLogger("requestBids", filteredDemandManagerSlots, "SLOTDATA");

    pbjs.que.push(function() {
      pbjs.rp.requestBids({
        callback: function() {
          headerBidderBack("prebid", requestManager, bidders, filteredDemandManagerSlots, qsc);
        },
        gptSlotObjects: filteredDemandManagerSlots
      });
    });
  }

  return filteredDemandManagerSlots;
};

// FUNCTION TO CHANGE REQUESTMANAGER.[BIDDER] VALUE TO TRUE
// IF ALL BIDDERS HAVE RETURNED BIDS, SEND AD SERVER REQUEST
export const headerBidderBack = (bidder, requestManager, bidders, slots, qsc) => {
  if (requestManager.adserverRequestSent === true) {
    return;
  }
  if (bidder === "a9") {
    requestManager.a9 = true;
  } else if (bidder === "prebid") {
    requestManager.prebid = true;
  }
  if (allBiddersBack(bidders, requestManager)) {
    adLogger("headerBidderBack", `All are back - sending ad server request`, "INFO");
    sendAdserverRequest(requestManager, slots, qsc);
  }
}

export const sendAdserverRequest = (requestManager, slots, qsc) => {
  if (requestManager.adserverRequestSent === true) {
    return;
  }
  requestManager.adserverRequestSent = true;
  adLogger("sendAdserverRequest", slots, "SLOTDATA");
  if (typeof window.apstag !== 'undefined' && slots.length > 0) {
    googletag.cmd.push(function() {
      // THIS APSTAG METHOD WILL SET KEY/VALUE TARGETING ON GPT SLOT; DEMAND MANAGER DOES THIS AUTOMATICALLY
      apstag.setDisplayBids();
      googletag.pubads().refresh(slots, {changeCorrelator: false});
    });
  } else if (dfpMap[`${affiliateData.domain.dom}.${affiliateData.domain.tld}`].rubiconAdsEnabled === true && (typeof window.apstag === 'undefined') && disableAllHb(qsc) === false && slots.length > 0) {
    // if a9 is off, but rubicon is on, we refresh slots without apstag here
    googletag.pubads().refresh(slots, {changeCorrelator: false});
  }
  requestManager.a9 = false;
  requestManager.prebid = false;
}

// FUNCTION TO CHECK IF ALL BIDDERS HAVE RETURNED BIDS
export const allBiddersBack = (bidders, requestManager) => {
  var allBiddersBack =
    bidders
      .map(function(bidder) {
        return requestManager[bidder];
      })
      .filter(Boolean).length === bidders.length;
  return allBiddersBack;
}

// Set up A9 targeting needed to pass rg_a9b to google
export const setA9TargetingRTB = (bids, slots) => {
  const pagePlatform = getPlatform();
  const affiliateAbbrev = dfpMap[`${affiliateData.domain.dom}.${affiliateData.domain.tld}`].abbrev;
  const bidMap = {};
  for (let i = 0, len = bids.length; i < len; i++) {
    if (bids[i].slotID && bids[i].amznp) {
      bidMap[bids[i].slotID] = bids[i].amznp;
    }
  }
  for (let i = 0; i < slots.length; i++) {
    const divId = slots[i].slotMap.divId;
    if (bidMap[divId]) {
      const fold = slots[i].slotMap.slotTargeting.rg_atf ? "ATF" : "BTF";
      const a9b = slots[i].slotMap.slotTargeting.rg_iab
        ? `Advance_${affiliateAbbrev}_${fold}_${pagePlatform.toUpperCase()}_${bidMap[divId]}`
        : false;
      adLogger("setA9TargetingRTB", `Setting up targeting for A9, ${a9b}, for ${divId}`, "INFO");
      slots[i].setTargeting("rg_a9b", a9b);
      // https://advancelocal-product.atlassian.net/browse/DA-1423, REVGEN-1227 updates value
      slots[i].setTargeting("rg_gpid-tam", `${pagePlatform}-${slots[i].slotName}-${bidMap[divId]}`);
    }
  }
}
