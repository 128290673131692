import assign from 'lodash.assign';
import mergeWith from "lodash.mergewith";
import {
  affiliateData,
  dfpSlotMap,
  domainWhiteList,
  dfpMap,
} from './config/config';

export const once = (fn, context) => {
  let result;
  return function(...args) {
    if (fn) {
      result = fn.apply(context || this, args);
      fn = null;
    }
    return result;
  };
};

export const isFunctionCallFirst = (fn) => {
  if (typeof fn !== 'function') {
    throw new TypeError('Expected a function');
  }

  let runNumber = 0;

  return (...args) => {
    fn(...args, runNumber === 0);
    runNumber++;
  };
}

export const exists = (obj, path) => {
  const keys = Array.isArray(path) ? path : path.split('.');
  const next = keys.shift();
  return (obj[next] && (!keys.length || exists(obj[next], keys))) || false;
};

// finally moving this here so we can pass it around - creates an object from query string values
export const getQsc = () => {
  if (!affiliateData || !affiliateData.domain || typeof affiliateData.domain.search !== 'string') {
    throw new Error('Invalid or missing affiliateData.domain.search');
  }
  return createObjectFromQuery(affiliateData.domain.search.replace(/^\?/, ''));
};

/**
 * Retrieves the value at a given path of the object. If the value is undefined, returns a default value.
 * @param {Object} object - The object to query.
 * @param {Array} keys - The path of the property to get.
 * @param {*} [defaultValue] - The value returned if the resolved value is undefined.
 * @returns {*} - The resolved value or the default value.
 * @usage - pathValue(myObject, ['path', 'to', 'what', 'you', 'are', 'looking', 'for'], 0)
 */
export const pathValue = (obj, path, defaultVal) => {
  return path.reduce((newObj, val) => newObj && typeof newObj[val] !== 'undefined' ?
    newObj[val] : defaultVal, obj);
};

export const waitFor = (condition, callback, interval = 10) => {
  if (!condition()) {
    setTimeout(waitFor.bind(null, condition, callback), interval);
  } else {
    callback();
  }
};

export const uuid = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    let r = (Math.random() * 16) | 0,
      v = c == 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

export const getReferrerHostname = (url) => {
  if (!url) return null;
  try {
    const parsedUrl = new URL(url);
    return parsedUrl.hostname || null;
  } catch (error) {
    // Handle or log the error if necessary
    return null;
  }
};

/**
 * Fires a callback when the document is DOM Ready
 * @param {function} cb - The callback to be executed when DOM Ready
 */
export const domReady = (cb) => {
  // Check if document is already ready
  if (document.readyState === 'complete' || document.readyState === 'interactive') {
    // Call the callback immediately if the document is ready
    cb();
  } else {
    // Otherwise, wait for the DOMContentLoaded event
    document.addEventListener('DOMContentLoaded', cb);
  }
};

/**
 * Caching the prototype lookup of Object.hasOwn
 * https://caniuse.com/mdn-javascript_builtins_object_hasown
 */
export const has = (obj, key) => Object.hasOwn(obj, key);

/**
 * getPagetype gets the page type as a string.
 * @returns {string|boolean} The page type or false if not found.
 */
export const getPagetype = () => {
  return window.adiData?.pageType || window.m_page_type || false;
};

/**
 * Injects a script into the DOM
 * @param {string} src - The URL of the script source.
 * @param {Object} opts - An object full of attributes you'd like to add to the script.
 * @param {string} [id] - The ID you'd like the script to have.
 * @param {boolean} [isAsync=false] - Determines if the script should be loaded asynchronously.
 * @returns {promise} - Resolves with the script or fails with error message
 */
export const injectScript = (src, opts, id, isAsync = false) => {
  return new Promise((resolve, reject) => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = src;
    script.async = isAsync;
    if (typeof opts === "object") {
      Object.keys(opts).map(key =>
        script.setAttribute(`data-${key}`, opts[key])
      );
    }
    if (id) script.id = id;
    script.onload = () => resolve(script);
    script.onerror = () => reject('Failed to load script');
    const node = document.getElementsByTagName('script')[0];
    if (node) {
      node.parentNode.insertBefore(script, node);
    } else {
      document.head.appendChild(script);
    }
  });
};

/**
 * Gets a script from the DOM and optionally returns the data-attributes associated with it
 * @param {string} id - The ID of the script you'd like to get in the DOM
 * @param {boolean} [returnData=false] - Whether or not you'd like to return the data-attributes or only the script tag itself
 * @returns {HTMLElement|object} - Either the script tag or object with the script tag and data-attributes in it.
 */
export const getScript = (id, returnData = false) => {
  const script = document.querySelector(`script#${id}`);
  if (!script) {
    return null;
  }
  if (!returnData) {
    return script;
  }
  const attributes = script.attributes;
  return Object.keys(attributes).reduce((obj, key) => {
    /**
     * For some reason, safari 9.1 adds the 'length' attribute to the Object keys and that code will throw an error
     * Adding the sanity check because of this
     */
    if (key !== 'length' && attributes[key].nodeName.substring(0, 5) === 'data-') {
      return assign({}, obj, {
        [attributes[key].nodeName.substring(5)]: attributes[key].nodeValue
      });
    }
    return obj;
  }, {});
};

/**
 * Helper function that returns the top-most element given a set of coordinates.
 * @param {integer} x - The x coordinate of the point
 * @param {integer} y - The y coordinate of the point
 * @returns {HTMLElement} - The top-most element of the coordinates that were passed in
 */
const getElementFromPoint = (x, y) => document.elementFromPoint(x, y);
/**
 * Tells you whether the passed in element is in the viewport
 * @param {HTMLElement} elem - The element that you want to determine if it is in the viewport or not
 * @returns {boolean} - If the element is in the viewport
 */
export const isInViewport = element => {
  const rect = element.getBoundingClientRect();
  const width = document.documentElement.clientWidth || window.innerWidth;
  const height = document.documentElement.clientHeight || window.innerHeight;

  return width && height && [
    { x: rect.left, y: rect.top },
    { x: rect.right, y: rect.top },
    { x: rect.right, y: rect.bottom },
    { x: rect.left, y: rect.bottom }
  ].some(({ x, y }) => element.contains(getElementFromPoint(x, y)));
};

/**
 * This next thing is an array.From polyfill which gets added from Babel.  It does not work on any version of IE.
 * Babel recommends to use their npm module 'babel-polyfill' but that was really heavy (an extra 30KB minified!)
 * and seemed much slower than what we have now.
 */
if (!Array.from) {
  Array.from = object => [].slice.call(object);
}

/**
 * Performance now polyfill for IE9
 */
if (typeof window.performance.now !== "function") {
  window.performance.now = () =>
    new Date().getTime() - window.performance.timing.navigationStart;
}

/**
 * Polyfill for CustomEvent for environments that do not support it natively.
 * Simplified to check properly for the existence of CustomEvent as a constructor.
 */
(() => {
  try {
    // Attempt to create a new CustomEvent to check if the constructor exists
    new CustomEvent('test');
  } catch (e) {
    // Define the CustomEvent constructor polyfill
    function CustomEvent(event, params = { bubbles: false, cancelable: false, detail: null }) {
      const evt = document.createEvent('CustomEvent');
      evt.initCustomEvent(event, params.bubbles, params.cancelable, params.detail);
      return evt;
    }
    CustomEvent.prototype = window.Event.prototype;
    window.CustomEvent = CustomEvent;
  }
})();

export const getQueryString = () => {
  // Check if affiliateData and affiliateData.domain are defined and search is a string
  if (typeof affiliateData?.domain?.search === 'string') {
    return affiliateData.domain.search.substring(1);
  }
  return '';
};

/**
 * createObjectFromQuery returns an object of query parameters as key:value pairs
 * @memberOf queryUtils
 *
 * @param {string} str The query string to parse
 * @return {Object} An object of the URL query params in key:value format or an empty object
 */
export const createObjectFromQuery = (str) => {
  if (!str) {
    return {};
  }

  return str.split('&').reduce((acc, cur) => {
    let [key, value = 'true'] = cur.split('='); // Default to true for flags without an explicit value

    // Convert string representations of booleans to actual boolean values
    value = value === 'true' ? true : value === 'false' ? false : value;

    // Concatenate values for keys that appear more than once
    acc[key] = acc[key] ? `${acc[key]}, ${value}` : value;

    return acc;
  }, {});
};

/**
 * Determines if ads should be enabled based on the presence of a 'noads' query parameter or hash.
 * @param {boolean} enabled - The initial state of ads being enabled, defaults to true.
 * @return {boolean} - Returns false if 'noads=true' is found in the query string or hash, otherwise returns the initial state.
 */
export const checkAdsEnabled = (enabled = true) => {
  const queryString = getQueryString();
  const queryObj = createObjectFromQuery(queryString);
  const hashQueryFound = window.location.hash.includes('noads=true');

  // Disable ads if 'noads=true' is found in the query parameters or hash, otherwise keep the initial state.
  return !(queryObj.noads === 'true' || hashQueryFound) && enabled;
};

/**
 * Determines if header bidding should be enabled based on the 'dhb' query parameter or hash.
 * @param {boolean} enabled - The initial state of header bidding being enabled, defaults to true.
 * @return {boolean} - Returns false if 'dhb=true' is found in the query string or hash, otherwise returns the initial state.
 */
export const checkHeaderBidding = (enabled = true) => {
  const queryString = getQueryString();
  const queryObj = createObjectFromQuery(queryString);
  const hashQueryFound = window.location.hash.includes('dhb=true');

  // Disable header bidding if 'dhb=true' is found in the query parameters or hash, otherwise keep the initial state.
  return !(queryObj.dhb === 'true' || hashQueryFound) && enabled;
};

/**
 * setDebuggerLink adds a 'DEBUG ADS' link to the page, which launches the ads debugger
 * this is called when the adv_ads_debugger cookie is set.
 */
export const setDebuggerLink = () => {
  const body = document.getElementsByTagName('body')[0];
  const adDiv = document.createElement('div');
  const adLink = document.createElement('a');
  adLink.textContent = 'DEBUG ADS';
  adLink.href =
    'javascript:(function() {document.body.appendChild(document.createElement("script")).src="//static.advance.net/static/common/js/ads/debugger.v2.js";} )();';
  adLink.onclick = '';
  adDiv.style.textAlign = 'center';
  adLink.style.fontWeight = 'bold';
  adDiv.appendChild(adLink);
  body.appendChild(adDiv);
};

/**
 * getDomainParts returns an object of domain parts
 * @param {string} url an optional url to use to get domain parts for
 * @return {object} an object containing the domain parts
 */
export const getDomainParts = (url = '') => {
  // Assuming affiliateData.domain contains all necessary domain parts
  return {
    domain: affiliateData.domain.dom,
    type: affiliateData.domain.tld,
    subdomain: affiliateData.domain.sub,
    path: affiliateData.domain.pathname,
    query: affiliateData.domain.search.substring(1),
    hash: affiliateData.domain.hash,
  };
};

/**
 * Retrieves a specific cookie value - originally for REVGEN-892
 *
 * @param {string} name The name of the cookie to retrieve.
 * @return {string|null} The value of the cookie, or null if not found.
 */
export const getCookie = (name) => {
  // Match the cookie name followed by its value, capturing the value in a group
  const match = document.cookie.match(`(^|;) ?${name}=([^;]*)(;|$)`);
  return match ? match[2] : undefined;
};

/**
 * Retrieves a value from the browser's localStorage for a given key.
 * It searches within an array of objects stored as a JSON string under the specified key.
 * Each object in the array is expected to have a 'key' and 'value' property.
 *
 * @param {string} key The localStorage key under which the data is stored.
 * @param {string} searchKey The key within the stored data array to search for.
 * @return {any|null} The value associated with the searchKey, or null if not found or in case of an error.
 */
export const getLocalStorageValue = (key, searchKey) => {
  try {
    const rawData = localStorage.getItem(key) || '[]'; // Use an empty array as fallback
    const parsedData = JSON.parse(rawData).find((obj) => obj.key === searchKey);
    return parsedData ? parsedData.value : null;
  } catch (error) {
    console.error(`Error parsing localStorage data for key "${key}":`, error);
    return null; // Return null in case of error to maintain function signature
  }
};

/**
 * Normalizes and extends the slot map for a specific ad unit.
 * It first normalizes the slot map by adding additional elements via 'updateMap',
 * then merges this with the unit's own slot map if provided.
 *
 * @param {object} unit The ad unit to update the map for. Must have 'container' or 'position'.
 * @param {string} type The type of ad, defaults to 'injected'.
 * @param {string} platform The platform to get the dfpSlotMap for.
 * @return {object} The final merged slot map for the unit.
 */
export const updateSlotMap = (unit, type = 'injected', platform) => {
  if (!unit || !platform) {
    throw new Error('Invalid input: "unit" and "platform" are required.');
  }

  const slotMap = dfpSlotMap[platform];
  const extendMap = unit.slotMap || {};

  const divId = typeof unit.container === 'object' ? unit.container.id : unit.container || unit.position;
  const slotName = unit.position;

  const updateMap = {
    divId,
    adType: type,
    slotName,
    slotTargeting: {}
  };

  // merge the extend map with the default map.
  const mergedMap = mergeWith({}, slotMap[slotName], updateMap);
  return mergeWith({}, mergedMap, extendMap);
};

export const sanitizeContext = {
  /**
   * sanitizeAll sanitizes a context by running through all of the sanitization functions
   * @param  {string} context the context to sanitize
   * @return {string}         the final sanitized context
   */
  sanitizeAll(context) {
    return this.isEmptyOrInvalid(context) ? '' : this.sanitizeFile(this.sanitizeDate(this.sanitizeDigits(context)));
  },

  isEmptyOrInvalid(context) {
    return !context;
  },

  /**
   * Removes all characters from the input string starting from the first occurrence of a sequence of four or more digits.
   * @param  {string} context - The URL or path to sanitize.
   * @return {string} - The sanitized URL or path.
   *
   * @usage sanitizeDate('http://highschoolsports.nj.com/news/article/1340819863127880334/franklin-is-the-njcom-girls-basketball-team-of-the-year-for-2016-17/');
   * @output 'http://highschoolsports.nj.com/news/article'
   */
  sanitizeDigits(context) {
    const whiteListed = domainWhiteList.some(val => context.includes(val));
    return whiteListed ? context : context.replace(/\/[\W?\d?]+\/.*/i, '');
  },

  /**
   * Removes all content from the input string starting from the matched pattern /yyyy/dd/.
   * @param  {string} context - The URL or path to sanitize.
   * @return {string} - The sanitized URL or path.
   *
   * @usage sanitizeDate('http://www.nj.com/eagles/2017/08/eagles_53-man_roster_prediction_green_bay_packers.html#incart_2box_sports');
   * @output 'http://www.nj.com/eagles'
   */
  sanitizeDate(context) {
    return context.replace(/\/\d{4}\/\d{2}\/.*/i, '');
  },

  /**
   * Sanitizes a URL or path by converting 'index.html' to 'index.ssf'. If 'index.ssf' is already present, or
   * if another file name is present, it removes the file name, keeping the path intact.
   * @param {string} context - The URL or path to sanitize.
   * @return {string} - The sanitized URL or path.
   *
   * @usage sanitizeDate('http://highschoolsports.nj.com/index.html');
   * @output 'http://highschoolsports.nj.com/index.ssf'
   */
  sanitizeFile(context) {
    if (context.includes('/index.ssf')) {
      return context;
    }

    if (context.includes('index.html')) {
      return context.replace('index.html', 'index.ssf');
    }

    return context.replace(/\/[^\\/]+\.[^\\/]+$/, '');
  }
};
