/**
 * @file api.js
 * @author Michael Cacciatore
 */
import { adLogger } from './loggerV2';
import { injectScript, updateSlotMap, uuid } from './utils';
import { dfpSlotMap, dfpMap, getPlatform } from './config/config';
import mergeWith from "lodash.mergewith";

/**
 * API class that either gets put in the global scope or gets returned from a require() statement
 * @param {object} wrapper - The wrapper that is located in the index.js file which acts as a bridge between this file and that file
 * @export
 * @class API
 */
export default class API {
  // opts.platform is needed for Jest
  constructor(wrapper, 
    opts = {
    "platform": getPlatform()
    },
    domain) {
    this.ready = false;
    this.wrapper = wrapper;
    this.isRevgen = true;
    this.context = null;
    this.platform = opts.platform !== undefined ? opts.platform : getPlatform();
    this.dfpSlotMap = dfpSlotMap[this.platform];
    this.dfpEntry = dfpMap[domain];
    let debugMode = process.env.NODE_ENV !== 'production';

    /* We needed a way to pass values in order to set up Slot Targeting before ads loaded from the Fusion side directly from our ads component */
    this.addSlotTargeting = (position, additionalTargeting) => {
      if (typeof position !== 'undefined' && position !== '' && typeof additionalTargeting !== 'undefined') {
        Object.assign(window.AdManager.dfpSlotMap[position].slotTargeting, additionalTargeting);
      }
    }

    /**
     * normalizeOptions
     * @param {object|array} opts - Used to pass in the options in an assortments of ways for the new unit to be made
     * @return {array}      [description]
     */
    this.normalizeOptions = (opts, type) => {
      if (opts) {
        let options = opts;
        if (Array.isArray(options) && options.length) {
          // If the array is an array of arrays convert it to an object
          // injectUnit([['position', 'container'], ['position', 'container']]) becomes
          // injectUnit([{'position', 'container'}, {'position', 'container']})
          if (Array.isArray(options[0])) {
            options = options.map((value) => {
              if (!!value[0] && !!value[1]) {
                return { position: value[0], container: value[1] };
              }
            });
          }
          // if we have an array convert it to an array of objects;
          if (typeof options[0] !== 'object') {
            // injectUnit(['position', 'container'])
            if (!!options[0] && !!options[1]) {
              options[0] = { position: options[0], container: options[1] };
              options.splice(1);
            }
          }
        }

        // if options is an object convert to an array of object
        if (typeof options === 'object' && !!options.container && !!options.position) {
          options = [options];
        }

        // convert options to our new format
        const newOptions = options.map(val => {
          const newOption = mergeWith({}, updateSlotMap(val, type, this.platform));

          return newOption;
        });
        return newOptions;
      }

      return null;
    };

    /**
     * Privileged method that injects a unit
     * @param {object|array} opts - Used to pass in the options in an assortments of ways for the new unit to be made
     * the ad if it already exists. Don't use if already passing in an object
     * @memberOf API.Prototype
     * @returns {string|array} - Returns the name of the units that were injected
     */

    this.injectUnit = (units, adConfig = {}, callback = null) => {
      if (!units) {
        return null;
      }

      const injectUnits = this.normalizeOptions(units, 'injected');
      const refreshArray = [];
      let i = injectUnits.length;

      while (i--) {
        if (!document.getElementById(injectUnits[i].divId)) {
          adLogger("API", `${injectUnits[i].divId} does not exist in the dom`, "LOGGERV1");
          continue;
        }
        // check if this id already exists
        if (this.wrapper.unitExists(injectUnits[i].divId)) {
          // send to the refresh array
          refreshArray.push(injectUnits[i].divId);
          // remove this unit from the injectable  array
          injectUnits.splice(i, 1);
        }
      }

      if (refreshArray.length) {
        refreshArray.forEach(refreshUnit => {
          this.wrapper.refreshUnit(refreshUnit, callback);
        });
      }

      if (injectUnits.length) {
        this.wrapper.injectUnit(injectUnits, adConfig, callback);
      }

      return null;
    };
  }

  /**
   * Refreshes an ad
   * @param {array|HTMLElement} opts - The ad unit container that you want to be refreshed (or an array of)
   * @returns {string|array} - A list of unit names that were refreshed
   * @memberOf API
   */
  refreshUnits(opts, callback = null) {
    if (opts) {
      if (Array.isArray(opts)) {
        return opts.map((value) => {
          if (value instanceof Element) {
            return this.wrapper.refreshUnit(value.id, callback);
          }
          return this.wrapper.refreshUnit(value, callback);
        });
      }

      if (opts instanceof Element) {
        return this.wrapper.refreshUnit(opts.id, callback);
      }

      if (typeof opts === 'string') {
        return this.wrapper.refreshUnit(opts, callback);
      }

      return this.wrapper.refreshUnit(opts, callback);
    }

    return null;
  }

  refreshUnit(opts, callback = null) {
    return this.refreshUnits(opts, callback);
  }

  /**
   * Refreshes all visible ad units in the current viewport
   * @returns {string|array} - A list of unit names that were refreshed
   * @memberOf API
   */
  refreshViewableUnits() {
    this.wrapper.refreshViewableUnits();
  }

  // here we are initiating a timed refresh function deliniated in https://jira.advance.net/browse/REVGEN-789
  timedRefresh() {
    this.wrapper.timedRefresh();
  }

  /**
   * Creates a smart ad that utilizes sleeper.  Smart ads are ads that are auto-injected when the user scrolls to the div
   * @param {HTMLElement|string} element - The id of the element (or the element itself) you wish to create the smart ad in.
   * @param {object} [opts={}] - Object of sleeper options.  Can be found here: https://stash.advance.net/projects/REV/repos/sleeper/browse/readme.md
   * @returns {string} - The id of the created smart ad.
   * @memberOf API
   */
  makeSmartAd(element, opts = {}) {
    const container = element instanceof Element ? element : document.getElementById(element);
    if (container) {
      const position = opts.position || container.getAttribute('title');
      if (!container.id) {
        container.id = `${position}_${Date.now()}`;
      }

      // if (('_sleeper' in window) && opts && ('inViewOffset' in opts) && opts.inViewOffset === 0) {
      //   console.log(`calling injectUnit for ${container.id}`);
      //   return;
      //   // return this.wrapper.injectUnit([{ divId options });
      // }

      const slotMap = opts.slotMap || {};
      const options = {
        container,
        position,
        context: opts.context || null,
        slotMap: updateSlotMap({ position, container, slotMap }, 'injected', this.platform),
      };
      return this.wrapper.makeSmartAd(options);
    }
    return null;
  }

  /**
   * Exposes the updateEmbeddedTags method of the wrapper.
   * Updates all embedded tags.
   *
   * @param {obj} newConfigObj The config object containing the new embeddedTags
   * @memberOf API
   */
  updateEmbeddedTags(newConfigObj) {
    const configObj = newConfigObj || {};
    this.wrapper.updateEmbeddedTags(configObj);
  }

  /**
   * Exposes the updateGAMPageTargeting method of the wrapper.
   * Updates page targeting for GAM for the next ad refresh.
   * @param {object} GAMTargeting
   * @memberOf API
   */
  updateGAMPageTargeting(GAMTargeting) {
    this.wrapper.updateGAMPageTargeting(GAMTargeting);
  }

  /**
   * Sets the context of the page
   * @param {string} context - The context to set the page to for OasAdsHost // Oas has been removed feature/REVGEN-830
   * @returns {string} - The new context
   * @memberOf API
   */
  set CONTEXT(context) {
    this.context = context;
    this.wrapper.setContext(context);
    return this.context;
  }

  /**
   * Returns the current context
   * @returns {string} - The current context
   * @memberOf API
   */
  getContext() {
    return this.wrapper.getContext();
  }

  /**
   * Returns the current platform used by revgen to deliver ads.
   * @returns {string} - The current platform ( desktop/mobile )
   * @memberOf API
   */
  getPlatform() {
    return this.platform;
  }

  /**
   * Returns an analytic value such as bids, lotame id, audiences, etc.
   * @param {string} value - The name of the value you wish to get
   * @returns {array|string|object} - The value you requested.
   * @memberOf API
   */
  getValue(value) {
    return this.wrapper.getValue(value);
  }
}
