import { getCookie } from '../utils';
import { adLogger } from '../loggerV2';

const targetAuthKey = 'rg_auth';

export const initAuthStatus = () => {
  const caleCookieVal = getCookie('cale_results');
  adLogger("authStatus - initAuthStatus", `Got cale_results cookie value: ${caleCookieVal}`, "INFO");

  let targetAuthValue = 'false';
  let targetSubValue = 'false';
  if (caleCookieVal) {
    // cale cookie is defined, which means user is logged in
    if (caleCookieVal.includes('true')) {
      // User is subscribed
      targetAuthValue = 'true_sub';
      targetSubValue = 'true';
    } else {
      // Logged in but not subscribed
      targetAuthValue = 'true_reg';
    }
  } else {
    // User is not logged in
    targetAuthValue = 'false';
  }

  adLogger("authStatus - initAuthStatus", `${targetAuthKey} targeting will be set now to ${targetAuthValue}`, "INFO");
  googletag.pubads().setTargeting(targetAuthKey, targetAuthValue);

  // REVGEN-1199 - when we know subscriber status, set it in window.AdManager for the sticky ad in fusion
  adLogger("subscriberStatus", `Subscriber Status for the Sticky Ad is ${targetSubValue}`, "INFO");
  window.AdManager.subStatus = targetSubValue;
}
